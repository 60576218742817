import { DEFAULT_LIMIT } from "@common/constant";
import {
  ICreateBookingMeal,
  ICreateBookingMealBody,
  IExportBookingMeal,
  IGetBookingMealDetail,
  IGetBookingMealList,
  IQueryBookingMeal
} from "@domain/interfaces/booking-meal-interface";
import BookingMealRepository from "@domain/repositories/booking-meal-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class BookingMealApiRepository implements BookingMealRepository {
  private apiService = new ApiService();

  async getList (query: IQueryBookingMeal): Promise<IGetBookingMealList> {
    const result = await this.apiService.get("/booking-meal", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: ICreateBookingMealBody): Promise<ICreateBookingMeal> {
    const result = await this.apiService.post("/booking-meal", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetBookingMealDetail> {
    const result = await this.apiService.remove(`/booking-meal/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
  async export (body: IExportBookingMeal): Promise<void> {
    await this.apiService.save("/booking-meal/report", "POST", "booking-meal.xlsx", body);
  }
}
